import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { List } from 'immutable';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  beginsWithVowel,
  isUserVerified,
  flashHighlight,
  // loadExternalScript,
  isUserCreative,
  toSeoUrl,
  get,
} from 'helpers/HelperFunctions';
import { trackEvent } from 'helpers/Analytics';
import config from 'config';

import PaymentModal from 'components/Pitch/PaymentModal';
import AddPaymentForPitch from 'components/Pitch/AddPaymentForPitch';
import AlertBox from 'components/AlertBox/AlertBox';
import HireUser from 'components/Portfolio/HireUser';
import Modal from 'components/Modal/Modal';
import PortfolioAbout from 'components/Portfolio/PortfolioAbout/PortfolioAbout';
import PortfolioContent from 'components/Portfolio/PortfolioContent';
import PortfolioHeader from 'components/Portfolio/PortfolioHeader/PortfolioHeader';
import ReportUser from 'components/Portfolio/ReportUser';
import PortfolioTour from 'components/Portfolio/PortfolioTour/PortfolioTour';
import TestimonialsAdd from 'components/Testimonials/TestimonialsAdd';
import UserTestimonials from 'components/Portfolio/UserTestimonials';
import VerifyUser from 'components/Portfolio/VerifyUser';
import NotFoundContent from 'components/NotFoundContent/NotFoundContent';
import ImageCropper from 'components/ImageCropper2/ImageCropper2';
import { INVITE_SENT_SUCCESS } from 'constants/successMessages';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import queryString from 'query-string';

export default class Portfolio extends PureComponent {
  static propTypes = {
    briefEntities: PropTypes.object,
    creditEntities: PropTypes.object,
    loadedSuggestedRoles: PropTypes.bool,
    loadingSuggestedRoles: PropTypes.bool,
    loadedSuggestedSkills: PropTypes.bool,
    loadingSuggestedSkills: PropTypes.bool,
    loadingPartners: PropTypes.bool,
    loadedPartners: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    partners: PropTypes.array,
    paymentToken: PropTypes.string,
    pitches: PropTypes.object,
    portfolio: PropTypes.object,
    portfolioCarousel: PropTypes.array,
    projectEntities: PropTypes.object,
    roleEntities: PropTypes.object,
    sendingUserReport: PropTypes.bool,
    sentUserReports: PropTypes.object,
    suggestedRoles: PropTypes.array,
    suggestedSkills: PropTypes.array,
    testimonialEntities: PropTypes.object,
    userEntities: PropTypes.object,
    voucherEntities: PropTypes.object,
    updatingUser: PropTypes.bool,
    user: PropTypes.object,
    history: PropTypes.object,
    // functions
    acceptPitch: PropTypes.func,
    connectPayPal: PropTypes.func,
    shortlist: PropTypes.func,
    getPaymentToken: PropTypes.func,
    inviteUser: PropTypes.func,
    isBriefsLoaded: PropTypes.func,
    isCreditsLoaded: PropTypes.func,
    isShortlisted: PropTypes.func,
    isOwnBriefsLoaded: PropTypes.func,
    isSuggestedRolesLoaded: PropTypes.func,
    isSuggestedSkillsLoaded: PropTypes.func,
    isUserLoaded: PropTypes.func,
    loadBriefs: PropTypes.func,
    loadCredits: PropTypes.func,
    loadPartners: PropTypes.func,
    loadUserTour: PropTypes.func,
    loadOwnBriefs: PropTypes.func,
    loadPendingPitches: PropTypes.func,
    loadSuggestedRoles: PropTypes.func,
    loadSuggestedSkills: PropTypes.func,
    loadTestimonials: PropTypes.func,
    loadUser: PropTypes.func,
    reorderCredits: PropTypes.func,
    updateUserTour: PropTypes.func,
    sendUserReport: PropTypes.func,
    setSuccessMessage: PropTypes.func,
    unshortlist: PropTypes.func,
    verify: PropTypes.func,
    updateUser: PropTypes.func,
    updateLocalUser: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      addTestimonial: false,
      braintreeNonce: '',
      contentEditMode: false,
      headerEditMode: false,
      editBio: '',
      editDayRate: props.user ? props.user.day_rate : '',
      editDayRateCurrency: props.user ? props.user.day_rate_currency : 0,
      editAvailability: props.user ? props.user.available_at : '',
      editLocation: { selected: false },
      hireRole: null,
      reportComments: '',
      reportReason: 'Inappropriate content uploaded',
      selectedBrief: null,
      selectedBriefVoucher: null,
      selectedPitch: null,
      showAbout: props.match.params.option === 'about',
      showAcceptPitch: false,
      showConnectPayment: false,
      showEmailVerifyModal: false,
      showHireModal: props.match.params.option === 'hire',
      showHireByDayModal: false,
      showReportModal: props.match.params.option === 'report',
      showTestimonialsModal: props.match.params.option === 'testimonials',
      showVerifyModal: props.match.params.option === 'verify',
      showUpdateAvatar: false,
      showUpdateCover: false,
      verificationUrl: '',
      showMoreAbout: false,
      showPortfolioTour: false,
      testingPartners: props.location.query.testing ?? false,
    };
  }

  componentDidMount() {
    const { username } = this.props.match.params;
    const isLoggedIn = this.props.user !== undefined;

    if (queryString.parse(this.props.location.search).success === null) {
      trackEvent('user-subscribed');
    }

    trackEvent('user-landed-on-portfolio', {
      portfolioUsername: username,
      loggedIn: isLoggedIn,
      ownPortfolio:
        isLoggedIn && this.props.user.username === username ? true : false,
    });

    // Load user if we haven't already
    if (
      !this.props.portfolio.getIn([username, 'loadedUser'], null) &&
      !this.props.portfolio.getIn([username, 'loadingUser'], null)
    ) {
      this.props.loadUser(username);
    }

    this.props.loadPartners('portfolio', this.state.testingPartners);

    if (
      !this.props.portfolio.getIn([username, 'loadedTestimonials'], null) &&
      !this.props.portfolio.getIn([username, 'loadingTestimonials'], null)
    ) {
      this.props.loadTestimonials(username);
    }

    // Load briefs if we haven't already
    if (
      !this.props.portfolio.getIn([username, 'loadedBriefs'], null) &&
      !this.props.portfolio.getIn([username, 'loadingBriefs'], null)
    ) {
      this.props.loadBriefs(username);
    }

    // Load tour data if we haven't already
    if (
      this.props.user &&
      this.props.user.username === username &&
      !this.props.portfolio.getIn([username, 'loadedUserTour'], null) &&
      !this.props.portfolio.getIn([username, 'loadingUserTour'], null)
    ) {
      this.props.loadUserTour(username);
    }

    // Load projects if we haven't already
    if (
      !this.props.portfolio.getIn([username, 'loadedCredits'], null) &&
      !this.props.portfolio.getIn([username, 'loadingCredits'], null)
    ) {
      this.props.loadCredits(username);
    }

    if (
      !this.props.loadedSuggestedRoles &&
      !this.props.loadingSuggestedRoles &&
      !this.props.loadedSuggestedRoles
    ) {
      this.props.loadSuggestedRoles();
    }

    if (
      !this.props.loadedSuggestedSkills &&
      !this.props.loadingSuggestedSkills &&
      !this.props.loadedSuggestedSkills
    ) {
      this.props.loadSuggestedSkills();
    }

    // Load shortlist state if we haven't already
    if (
      this.props.user &&
      this.props.user.username !== username &&
      !this.props.portfolio.hasIn([username, 'isShortlisted']) &&
      !this.props.portfolio.getIn([username, 'loadingShortlistState'], false)
    ) {
      this.props.isShortlisted(username);
    }

    // Load own briefs if we haven't already
    if (
      this.props.user &&
      this.props.user.username !== username &&
      !this.props.portfolio.getIn(
        [this.props.user.username, 'loadedOwnBriefs'],
        null
      ) &&
      !this.props.portfolio.getIn(
        [this.props.user.username, 'loadingOwnBriefs'],
        null
      )
    ) {
      this.props.loadOwnBriefs(this.props.user.username);
    }

    // load pending pitches
    if (
      (this.props.user &&
        this.props.user.username !== username &&
        !this.props.pitches) ||
      (this.props.pitches &&
        !this.props.pitches.getIn([username, 'loading']) &&
        !this.props.pitches.getIn([username, 'loaded']))
    ) {
      this.props.loadPendingPitches(username);
    }

    document.addEventListener('scroll', this.handleScroll);

    if (typeof this.props.location.query.edit !== 'undefined') {
      this.handleEditMode('headerEditMode');
    }

    if (typeof this.props.location.query.highlight !== 'undefined') {
      setTimeout(() => {
        const { query } = this.props.location;
        flashHighlight(query.highlight);

        const queries = query;
        delete queries.highlight;
        this.props.history.replace({
          pathname: this.props.location.pathname,
          query: queries,
        });
      }, 500);
    }

    if (__CLIENT__) {
      const portfolioGridHeaderCount =
        document.getElementsByClassName('portfolioGrid');

      if (portfolioGridHeaderCount.length > 1) {
        setTimeout(() => {
          document.location.reload();
        }, 2000);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sendingUserReport &&
      !this.props.sendingUserReport &&
      prevProps.sentUserReports.length < this.props.sentUserReports.length
    ) {
      this.props.setSuccessMessage('Report submitted');
    }

    // Prompt if not logged in
    if (
      prevProps.match.params.username !== this.props.match.params.username &&
      !this.props.user
    ) {
      if (this._upsellModalTimer) {
        clearTimeout(this._upsellModalTimer);
      }

      this._upsellModalTimer = setTimeout(
        () => this.setState({ showHireModal: true }), // eslint-disable-line react/no-did-update-set-state
        10000
      );
    }

    if (prevProps.updatingUser && !this.props.updatingUser) {
      this.handleEditModeDone();
    }

    if (
      typeof this.props.location.query.edit !== 'undefined' &&
      prevProps.location.query.edit !== this.props.location.query.edit
    ) {
      if (
        (this.props.location.query.edit === '0' && this.state.headerEditMode) ||
        (this.props.location.query.edit === '1' && !this.state.headerEditMode)
      ) {
        this.handleEditMode('headerEditMode');
      }
    }

    if (
      typeof this.props.location.query.highlight !== 'undefined' &&
      prevProps.location.query.highlight !== this.props.location.query.highlight
    ) {
      flashHighlight(this.props.location.query.highlight);

      const queries = this.props.location.query;
      delete queries.highlight;
      this.props.history.replace({
        pathname: this.props.location.pathname,
        query: queries,
      });
    }

    if (
      prevProps.match.params.option === 'about' &&
      this.props.match.params.option !== 'about'
    ) {
      this.setState({ showAbout: false }); // eslint-disable-line react/no-did-update-set-state
    }

    // When user tour data has loaded
    const {
      match: {
        params: { username },
      },
    } = this.props;

    if (this.props.portfolio.getIn([username, 'loadedUserTour'], false)) {
      const prevLoaded = prevProps.portfolio.getIn(
        [username, 'loadedUserTour'],
        false
      );
      const currLoaded = this.props.portfolio.getIn(
        [username, 'loadedUserTour'],
        false
      );

      if (!prevLoaded && currLoaded) {
        this.handleTourDataLoaded();
      }
    }
  }

  componentWillUnmount() {
    if (__CLIENT__) {
      document.removeEventListener('scroll', this.handleScroll);
      window.onbeforeunload = null;
    }
  }

  static _upsellModalTimer = null;

  handleHireClick = (user, role) => {
    this.setState({
      hireRole: null,
      showHireModal: false,
    });

    this.props.history.push(`/post/${role}?freelancerId=${user.id}`);
  };

  handleReport = (user, reason, comments) => {
    this.props.sendUserReport(user, reason, comments);

    this.setState({
      reportComments: '',
      reportReason: 'Inappropriate content uploaded',
      showReportModal: false,
    });
  };

  handleScroll = () => {
    if (document.getElementById('portfolio-credits')) {
      const distanceFromBottom =
        document.getElementById('portfolio-credits').getBoundingClientRect()
          .bottom - window.innerHeight;

      const { username } = this.props.match.params;

      if (
        distanceFromBottom < 250 &&
        this.props.portfolio.getIn([username, 'loadedCredits'], false) &&
        !this.props.portfolio.getIn([username, 'loadingCredits'], false) &&
        this.props.portfolio.getIn([username, 'moreCredits'], false)
      ) {
        this.props.loadCredits(
          username,
          this.props.portfolio.getIn([username, 'creditPagesLoaded'], 0) + 1
        );
      }
    }
  };

  handleToggleShortlist = () => {
    const { username } = this.props.match.params;

    if (!this.props.user) {
      document.location = `/signin?referrer=/${username}`;
    }

    if (this.props.portfolio.getIn([username, 'isShortlisted'], false)) {
      this.props.unshortlist(username);
    } else {
      trackEvent('user-shortlists-freelancer', {
        shortlist_type: 'portfolio',
      });
      this.props.shortlist(username);
    }
  };

  handleVerification = (user, url) => {
    this.props.verify(user.username, url);
  };

  handleHireMe = (brief = null) => {
    if (!brief) {
      this.setState({
        showHireModal: true,
      });
    } else {
      trackEvent('user-invites-freelancer', { type: 'portfolio' });
      this.props
        .inviteUser(
          this.props.user,
          this.props.portfolio.getIn([this.props.match.params.username, 'id']),
          brief
        )
        .then(() => {
          this.props.setSuccessMessage(INVITE_SENT_SUCCESS);
        })
        .catch(() => {});
    }
  };

  handleHireByDay = (brief = null) => {
    if (!brief) {
      this.setState({
        showHireByDayModal: true,
      });
    }

    trackEvent('hire-by-day-rate');
  };

  handleAccept = (totalToPay) => {
    const { selectedBrief, selectedPitch } = this.state;

    if (selectedBrief && selectedPitch) {
      this.props.history.push(
        `/manage/briefs/${selectedBrief.id}/pay/${selectedPitch.id}?amount=${
          totalToPay * 100
        }&isHire=true`
      );
    }
  };

  handlePaymentMethodReceived = (obj) => {
    this.setState(
      {
        braintreeNonce: obj.nonce,
      },
      () => {
        this.paymentMethodAdded();
      }
    );
  };

  handleEditMode = (editMode) => {
    const { updateUser } = this.props;
    const {
      editBio,
      editLocation,
      editDayRate,
      editDayRateCurrency,
      editAvailability,
    } = this.state;

    const userId = this.props.portfolio.getIn([this.props.user.username, 'id']);
    const user = userId
      ? this.props.userEntities.get(userId.toString(), null).toJS()
      : this.props.user;

    if (this.state[editMode]) {
      const updates = {};
      if (editBio !== user.bio) {
        // update bio
        updates.bio = editBio;
      }

      if (editLocation.text !== user.location.text) {
        // update location
        updates.location = {
          lat: editLocation.lat,
          lon: editLocation.lon,
          city: editLocation.city,
          country: editLocation.country,
          country_code: editLocation.country_code,
          method: 'html5',
        };
      }

      if (editDayRate !== user?.day_rate) {
        if (editDayRate / 100 > 3000) {
          updates.day_rate = 3000 * 100;
        } else if (editDayRate == 0 || editDayRate == '') {
          updates.day_rate = '';
        } else {
          updates.day_rate = editDayRate;
        }
      }

      if (editDayRateCurrency !== user.day_rate_currency) {
        updates.day_rate_currency = editDayRateCurrency;
      }

      if (editAvailability !== user.available_at) {
        updates.available_at = editAvailability;
      }

      if (Object.keys(updates).length > 0) {
        updateUser(user, updates, false, [], '', editMode);
      } else {
        this.handleEditModeDone();
      }
    } else {
      if (__CLIENT__) {
        // Enable navigation prompt
        window.onbeforeunload = () => true;
      }

      this.setState({
        [editMode]: true,
        showAbout: true,
        editBio: user.bio,
        editDisplayname: user.displayname,
        editLocation: { ...editLocation, text: user.location.text },
      });
    }
  };

  handleEditModeDone = () => {
    if (__CLIENT__) {
      window.onbeforeunload = null;
    }

    this.setState(
      {
        contentEditMode: false,
        headerEditMode: false,
        editBio: '',
        editLocation: { selected: false },
        showUpdateCover: false,
        showUpdateAvatar: false,
      },
      () => {
        const queries = this.props.location.query;
        delete queries.edit;
        this.props.history.replace({
          pathname: this.props.location.pathname,
          query: queries,
        });
      }
    );
  };

  handleTourDataLoaded = () => {
    const {
      match: {
        params: { username },
      },
      portfolio,
    } = this.props;

    const portfolioTourCompleted = portfolio.getIn(
      [username, 'tour', 'completed_profile_onboarding'],
      true
    );

    if (!portfolioTourCompleted) {
      this.setState({
        showPortfolioTour: true,
      });
    }
  };

  handlePortfolioTourCompleted = () => {
    const { showPortfolioTour } = this.state;
    const {
      match: {
        params: { username },
      },
      updateUserTour,
    } = this.props;

    if (showPortfolioTour) {
      this.setState({
        showPortfolioTour: false,
      });
      const tourData = {
        completed_profile_onboarding: true,
      };
      updateUserTour(username, tourData);
    }
  };

  handleUpdateBio = (bio) => this.setState({ editBio: bio });

  handleUpdateLocation = (location) =>
    this.setState({ editLocation: { selected: true, ...location } });

  handleUpdateAvatar = (avatar) => {
    const userId = this.props.portfolio.getIn([this.props.user.username, 'id']);
    const user = userId
      ? this.props.userEntities.get(userId.toString(), null).toJS()
      : this.props.user;

    this.setState({ showUpdateAvatar: false });

    const updates = {};
    updates.avatar = avatar.image;

    updates.crop = {
      avatar: {
        h: avatar.height,
        w: avatar.width,
        x: avatar.x,
        y: avatar.y,
      },
    };

    this.props.updateUser(user, updates);

    const avatars = user.avatars;
    avatars.temp = avatar.croppedImage;

    this.props.updateLocalUser(userId, { avatars });
  };

  handleUpdateCover = (cover) => {
    const userId = this.props.portfolio.getIn([this.props.user.username, 'id']);
    const user = userId
      ? this.props.userEntities.get(userId.toString(), null).toJS()
      : this.props.user;

    this.setState({ showUpdateCover: false });

    const updates = {};
    updates.cover = cover.image;
    updates.crop = {
      cover: {
        h: cover.height,
        w: cover.width,
        x: cover.x,
        y: cover.y,
      },
    };

    this.props.updateUser(user, updates);

    const covers = user.covers;
    covers.temp = cover.croppedImage;
    this.props.updateLocalUser(userId, { covers });
  };

  showBraintree = (pitch) => {
    const brief = pitch.notice;

    if (typeof pitch.sender !== 'object') {
      pitch.sender = this.props.userEntities[pitch.sender];
    }

    if (typeof brief.voucher !== 'object') {
      brief.voucher = this.props.voucherEntities[brief.voucher];
    }

    this.setState({
      showAcceptPitch: true,
      selectedBrief: brief,
      selectedBriefVoucher: brief.voucher,
      selectedPitch: pitch,
    });
  };

  paymentMethodAdded = () => {
    this.props.connectPayPal(this.props.user, this.state.braintreeNonce);

    this.setState({
      showConnectPayment: false,
      showAcceptPitch: true,
    });
  };

  isProjectless = (user) =>
    user?.stats.num_of_projects === 0 && user?.stats.num_of_credits === 0;

  openHireModal = (showHireModal) => {
    if (showHireModal === true) {
      trackEvent('user-opens-contact-modal');
    }
    return showHireModal;
  };

  render() {
    const {
      user,
      briefEntities,
      creditEntities,
      match: {
        params: { username },
      },
      pitches,
      portfolio,
      portfolioCarousel,
      projectEntities,
      roleEntities,
      sentUserReports,
      suggestedRoles,
      suggestedSkills,
      testimonialEntities,
      userEntities,
      partners,
      loadedPartners,
    } = this.props;

    const {
      hireRole,
      reportComments,
      reportReason,
      showEmailVerifyModal,
      showHireModal,
      showHireByDayModal,
      showReportModal,
      showTestimonialsModal,
      showVerifyModal,
      showUpdateAvatar,
      showUpdateCover,
      verificationUrl,
      showMoreAbout,
      showPortfolioTour,
    } = this.state;

    const viewUserId = portfolio.getIn([username, 'id']);

    const viewUserObj = viewUserId
      ? userEntities.get(viewUserId.toString(), null).toJS()
      : null;

    const isReorderingCredits = portfolio.getIn([
      username,
      'isReorderingCredits',
    ]);

    const portfolioCarouselIndex =
      viewUserObj && portfolioCarousel
        ? portfolioCarousel.indexOf(viewUserObj.id)
        : -1;

    const title = `${
      viewUserObj ? viewUserObj.displayname : username
    }'s portfolio on Twine`;
    const metaDescription = viewUserObj
      ? `${viewUserObj.displayname} is${
          (viewUserObj.top_roles.length > 0
            ? (beginsWithVowel(viewUserObj.top_roles[0]) ? ' an ' : ' a ') +
              viewUserObj.top_roles[0]
            : '') +
          (viewUserObj.location.text !== ''
            ? ' from ' + viewUserObj.location.text
            : '')
        } on Twine. View their freelance projects and hire them to work on your job.`
      : '';

    if (
      !isNaN(username) ||
      (portfolio.getIn([username, 'loadedUser'], null) && !viewUserObj)
    ) {
      return <NotFoundContent />;
    }

    return (
      <div>
        <Helmet
          title={title}
          link={[{ rel: 'canonical', href: `${config.baseUrl}/${username}` }]}
          meta={[
            { property: 'og:title', content: title },
            { name: 'twitter:title', content: title },
            { name: 'description', content: metaDescription },
            { property: 'og:description', content: metaDescription },
            { name: 'twitter:description', content: metaDescription },
            { property: 'og:url', content: `${config.baseUrl}/${username}` },
            {
              property: 'og:image',
              content:
                viewUserObj &&
                typeof viewUserObj.avatars.images['190'] !== 'undefined'
                  ? viewUserObj.avatars.images['190'].square.main
                  : '',
            },
            {
              name: 'twitter:image',
              content:
                viewUserObj &&
                typeof viewUserObj.avatars.images['190'] !== 'undefined'
                  ? viewUserObj.avatars.images['190'].square.main
                  : '',
            },
          ]}
        />
        {get(viewUserObj, ['rating', 'count'], 0) > 0 && (
          <div>
            <meta content={viewUserObj.displayname} />
            <meta content={viewUserObj.displayname} />
            <div>
              <span content={viewUserObj.rating.count} />
              <span
                content={
                  viewUserObj.rating.avg < 4 ? 4.1 : viewUserObj.rating.avg
                }
              />
            </div>
          </div>
        )}
        <PortfolioHeader
          existingBriefs={
            user
              ? portfolio
                  .getIn([user.username, 'ownBriefs'], List([]))
                  .map((briefId) => briefEntities.get(briefId.toString()))
                  .toJS()
              : []
          }
          loggedInUser={user}
          // editMode={headerEditMode}
          onUpdateAvatar={() => this.setState({ showUpdateAvatar: true })}
          onUpdateCover={() => this.setState({ showUpdateCover: true })}
          nextUser={
            portfolioCarouselIndex >= 0 &&
            portfolioCarouselIndex < portfolioCarousel.length - 1
              ? userEntities
                  .get(portfolioCarousel[portfolioCarouselIndex + 1].toString())
                  .toJS()
              : null
          }
          onAccept={this.showBraintree}
          onHire={(brief) => this.handleHireMe(brief)}
          onHireByDay={(brief) => this.handleHireByDay(brief)}
          onRatings={() => this.setState({ showTestimonialsModal: true })}
          onVerify={() => this.setState({ showVerifyModal: true })}
          verificationState={isUserVerified(viewUserObj)}
          pitches={
            pitches &&
            username &&
            pitches.getIn([username, 'pitches'], List()).toJS()
          }
          prevUser={
            portfolioCarouselIndex > 0
              ? userEntities
                  .get(portfolioCarousel[portfolioCarouselIndex - 1].toString())
                  .toJS()
              : null
          }
          user={viewUserObj}
          shortlistState={portfolio.getIn([username, 'isShortlisted'], false)}
          toggleShortlist={this.handleToggleShortlist}
        />
        <Grid>
          <Row>
            <Col
              md={6}
              mdPull={-6}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <PortfolioAbout
                loggedInUser={user}
                locationFindUrl={
                  viewUserObj && viewUserObj.top_roles.length > 0
                    ? '/find/' +
                      toSeoUrl(viewUserObj.top_roles[0]) +
                      's' +
                      (viewUserObj.location.country_code !== ''
                        ? '/' + viewUserObj.location.country_code
                        : '') +
                      (viewUserObj.location.country_code !== '' &&
                      viewUserObj.location.city_slug !== ''
                        ? '/' + viewUserObj.location.city_slug
                        : '')
                    : null
                }
                onEmailVerify={() =>
                  this.setState({ showEmailVerifyModal: true })
                }
                onReport={() => this.setState({ showReportModal: true })}
                testimonials={portfolio
                  .getIn([username, 'testimonials'], List([]))
                  .map((testimonialId) =>
                    testimonialEntities.get(testimonialId.toString())
                  )
                  .toJS()}
                onTestimonials={() =>
                  this.setState({ showTestimonialsModal: true })
                }
                // dayRate={
                //   contentEditMode
                //     ? editDayRate
                //     : viewUserObj
                //     ? viewUserObj.day_rate
                //     : null
                // }
                // dayRateCurrency={
                //   contentEditMode
                //     ? editDayRateCurrency
                //     : viewUserObj
                //     ? viewUserObj.day_rate_currency
                //     : null}
                sentReport={
                  viewUserObj && sentUserReports.includes(viewUserObj.id)
                }
                style={{ margin: '25px auto', paddingLeft: '0px' }}
                user={viewUserObj}
                // updatingUser={updatingUser}
                showMore={showMoreAbout}
                showShowMoreButton={
                  viewUserObj && viewUserObj?.bio.length > 200
                }
                onShowMore={() =>
                  this.setState({ showMoreAbout: !showMoreAbout })
                }
                isProjectless={this.isProjectless(viewUserObj)}
                isOwn={user && viewUserObj && user.id === viewUserObj.id}
                partners={partners}
                loadedPartners={loadedPartners}
              />
            </Col>
            <Col
              md={6}
              mdPush={-6}
              className={'desktopDisplayRightPadding desktopDisplayRightMargin'}
            >
              <PortfolioContent
                credits={portfolio
                  .getIn([username, 'credits'], List([]))
                  .map((creditId) => {
                    let credit = creditEntities.get(creditId.toString());
                    credit = credit.set(
                      'project',
                      projectEntities.get(credit.get('project_id').toString())
                    );
                    credit = credit.setIn(
                      ['roles'],
                      credit
                        .getIn(['roles'], [])
                        .map((roleId) => roleEntities.get(roleId.toString()))
                    );
                    return credit;
                  })
                  .toJS()}
                id={this.isProjectless(viewUserObj) ? '' : 'portfolio-credits'}
                isOwn={user && viewUserObj && user.id === viewUserObj.id}
                isLoggedIn={typeof user !== 'undefined'}
                isReorderingCredits={isReorderingCredits}
                isCreative={isUserCreative(viewUserObj)}
                loadingCredits={portfolio.getIn(
                  [username, 'loadingCredits'],
                  false
                )}
                onReorderCredit={(id, position) =>
                  this.props.reorderCredits(username, id, position)
                }
                style={{ paddingRight: '0px', paddingLeft: '0px' }}
                user={viewUserObj}
                userViewingPage={user}
                // userRank={computeUserRank(user)}
                loggedInUser={user}
                isProjectless={this.isProjectless(viewUserObj)}
                partners={partners}
                loadedPartners={loadedPartners}
              />
            </Col>
          </Row>
        </Grid>
        <Modal
          show={this.openHireModal(showHireModal)}
          onExit={() => this.setState({ showHireModal: false })}
          modalStyle={{ padding: 0 }}
        >
          <HireUser
            onHide={() => this.setState({ showHireModal: false })}
            user={viewUserObj}
            selectedRole={hireRole}
            suggestedRoles={suggestedRoles}
            suggestedSkills={suggestedSkills}
            onRoleSelect={(role) => this.setState({ hireRole: role })}
            onNext={(hireUser, role) => this.handleHireClick(hireUser, role)}
          />
        </Modal>
        <AlertBox
          show={showHireByDayModal}
          onConfirm={() => this.setState({ showHireByDayModal: false })}
          title="Pay Freelancer"
          text={
            <p style={{ textAlign: 'left' }}>
              {`If you're already working with the freelancer, go to `}
              <Link to="/manage/briefs">Manage Jobs</Link>
              {` and then select the
              job you've hired them for.`}
              <br />
              <br />
              {`If you've not worked with the freelancer before, you need to `}
              <Link to="/post">post a job with your requirements</Link>. After,
              come back to this portfolio page and click <strong>invite</strong>
              .
            </p>
          }
          icon="info"
          theme="info"
        />
        <Modal
          show={showReportModal}
          onExit={() => this.setState({ showReportModal: false })}
        >
          <ReportUser
            onChangeReportComments={(comments) =>
              this.setState({ reportComments: comments })
            }
            onChangeReportReason={(reason) =>
              this.setState({ reportReason: reason })
            }
            onReport={(reportUser, reason, comments) =>
              this.handleReport(reportUser, reason, comments)
            }
            reportComments={reportComments}
            reportReason={reportReason}
            user={viewUserObj}
          />
        </Modal>
        <Modal
          show={showEmailVerifyModal}
          onExit={() => this.setState({ showEmailVerifyModal: false })}
          closeButton
        >
          <div className="p++">
            <h3 className="lato-bold text-center mb">Verify your Email</h3>
            <p>
              Before you can edit your bio, you need to activate your account.
            </p>
            <p>
              Please check your email for an email from us with an activation
              button and click it.
            </p>
            <p>
              Please check your spam/junk folder in case it has ended up in
              there.
            </p>
          </div>
        </Modal>
        <Modal
          onEnter={() =>
            !portfolio.getIn([username, 'loadedTestimonials'], false)
              ? this.props.loadTestimonials(username)
              : null
          }
          onExit={() =>
            this.setState({
              showTestimonialsModal: false,
              addTestimonial: false,
            })
          }
          show={showTestimonialsModal}
          closeButton
        >
          {!this.state.addTestimonial ? (
            <UserTestimonials
              total={portfolio.getIn([username, 'testimonialTotal'], 0)}
              loadingTestimonials={portfolio.getIn(
                [username, 'loadingTestimonials'],
                false
              )}
              loggedInUser={user}
              more={portfolio.getIn([username, 'moreTestimonials'], false)}
              onLoadMore={() =>
                this.props.loadTestimonials(
                  username,
                  portfolio.getIn([username, 'testimonialPagesLoaded'], 0) + 1
                )
              }
              onAddTestimonial={() => this.setState({ addTestimonial: true })}
              testimonials={portfolio
                .getIn([username, 'testimonials'], List([]))
                .map((testimonialId) =>
                  testimonialEntities.get(testimonialId.toString())
                )
                .toJS()}
              user={viewUserObj}
            />
          ) : (
            <TestimonialsAdd />
          )}
        </Modal>
        <Modal
          show={showVerifyModal}
          onExit={() => this.setState({ showVerifyModal: false })}
        >
          <VerifyUser
            user={viewUserObj}
            currentStep={
              portfolio.getIn(
                [username, 'isVerified'],
                user ? user.verified : false
              )
                ? 2
                : 1
            }
            verificationUrl={verificationUrl}
            onValueChanged={(url) => this.setState({ verificationUrl: url })}
            onClickedVerify={(verifyUser, url) =>
              this.handleVerification(verifyUser, url)
            }
          />
        </Modal>
        {this.state.showConnectPayment && (
          <Modal
            show={this.state.showConnectPayment}
            onExit={() => this.setState({ showConnectPayment: false })}
          >
            <AddPaymentForPitch
              onCancel={() => this.setState({ showConnectPayment: false })}
              onPaymentMethodReceived={this.handlePaymentMethodReceived.bind(
                this
              )}
              paymentToken={this.props.paymentToken}
              pitch={this.state.selectedPitch}
            />
          </Modal>
        )}
        {this.state.showAcceptPitch && (
          <Modal
            show={this.state.showAcceptPitch}
            onExit={() => this.setState({ showAcceptPitch: false })}
          >
            <PaymentModal
              brief={this.state.selectedBrief}
              pitch={this.state.selectedPitch}
              onAccept={(totalToPay) => this.handleAccept(totalToPay)}
              hireOthers={() => this.setState({ showAcceptPitch: false })}
            />
          </Modal>
        )}
        <Modal
          show={showUpdateAvatar}
          onExit={() => this.setState({ showUpdateAvatar: false })}
          backgroundExit={false}
          closeButton
        >
          <div>
            <ImageCropper
              style={{ paddingTop: '30px' }}
              existingImage={viewUserObj?.avatars}
              existingImageCircle
              onDone={this.handleUpdateAvatar}
            />
            <p className="mt">
              <center style={{ color: '#aaa' }}>
                Need some help? Access our{' '}
                <a
                  href="https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Help Center
                </a>
              </center>
            </p>
          </div>
        </Modal>
        <Modal
          show={showUpdateCover}
          onExit={() => this.setState({ showUpdateCover: false })}
          backgroundExit={false}
          closeButton
          modalStyle={{ minWidth: '75%' }}
        >
          <div>
            <ImageCropper
              style={{ paddingTop: '30px' }}
              aspectRatio={3.9}
              existingImage={viewUserObj?.covers}
              onDone={this.handleUpdateCover}
            />
            <p className="mt">
              <center style={{ color: '#aaa' }}>
                Need some help? Access our{' '}
                <a
                  href="https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Help Center
                </a>
              </center>
            </p>
          </div>
        </Modal>
        {user && viewUserObj && user.id === viewUserObj.id && (
          <Modal backgroundExit={false} show={showPortfolioTour}>
            <PortfolioTour
              user={viewUserObj}
              loggedInUser={user}
              onExit={this.handlePortfolioTourCompleted}
            />
          </Modal>
        )}
      </div>
    );
  }
}
